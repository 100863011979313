import { useCallback, useEffect, useState } from 'react';
import { Container, Box, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { ADD_SITE_BTN, DEFAULT_SORT_BY_VALUE } from '../../config';
import { PlusFillIcon } from 'src/assets';
import { useDocumentTitle, useResponsive } from 'src/hooks';
import { StyledHeaderSiteContent, StyledSearchBox, addSiteBtnBox } from './style';
import { DesktopSiteTable, MobileSiteList } from '../../components';
import { SitesService } from 'src/services/sites';
import { ADD_SITE_PATH } from 'src/routes/config';
import { useAuthentication } from 'src/features/authentication/context';
import { AccessRoles } from 'src/features/user-account-details/types';
import { useTable } from 'src/components';
import { NewSite } from '../../types/sites';
import { SiteSearchBar } from '../../components/search';
import { SortListDropdown } from '../../components/search/search-bar/sort-dropdown';
import { TagsTypes } from '../../types/types';
import { EditSitePreviewRowType } from '../../types';
import { EditSitePreviewModal } from '../../components/edit-site-preview-modal';
import { useWebSockets } from 'src/contexts/webSocketsContext/websockets.context';
import { useStatsigClient } from '@statsig/react-bindings';

export const SiteManagement = () => {
  const {
    customerId: { value: customerId },
    getCurrentRole,
    user,
  } = useAuthentication();
  const isMobile = useResponsive('down', 'sm');
  const buttonSize = isMobile ? 'small' : 'medium';
  const [startFlag, setStartFlag] = useState(false);
  const [siteDataCount, setSiteDataCount] = useState<number>(0);
  const mobileLimit = 100;
  const [sortByValue, setSortByValue] = useState<undefined | string>(DEFAULT_SORT_BY_VALUE);
  const [sortByOrder, setSortByOrder] = useState<undefined | string>('');
  const [sortDropdownValue, setSortDropdownValue] = useState<undefined | string>('A-Z');
  const [skipTo, setSkipTo] = useState<string | undefined>(undefined);
  const [searchValue, setSearchValue] = useState<string>('');
  const [siteData, setSiteData] = useState<{ value: NewSite[] | null; loaded: boolean }>({
    value: [],
    loaded: false,
  });
  const [tags, setTags] = useState<TagsTypes | []>([]);
  const [liveData, setLiveData] = useState<any>([]);
  const isSuperAdmin = getCurrentRole(customerId, null) === AccessRoles.SUPER_ADMIN;
  const isAccountAdmin = getCurrentRole(customerId, null) === AccessRoles.ACCOUNT_ADMIN;
  const roleAccessInfoBox = isSuperAdmin || isAccountAdmin;
  const { messages } = useWebSockets();
  const client = useStatsigClient();

  const {
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    pages,
    setPages,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  useDocumentTitle('Streametric - Sites List');

  const [editSitePreviewDialogOpen, setEditSitePreviewDialogOpen] = useState(false);
  const [selectedSite, setSelectedSite] = useState<string | null>(null);
  const [rows, setRows] = useState<{ value: EditSitePreviewRowType[]; loaded: boolean }>({
    value: Array.from({ length: 1 }, (v, k) => k).map((k) => ({
      id: `${k + 1}`,
      position: k + 1,
      tagId: '',
      gatewayId: '',
      parentType: 'gateway',
    })),
    loaded: false,
  });

  const handleDialogOpen = (siteId: string | null) => {
    if (!customerId || !siteId) return;

    SitesService.getEditSitePreview(customerId, siteId)
      .then((response: any) => {
        const newRowsData: EditSitePreviewRowType[] = [];
        response.tags
          .sort((a: any, b: any) => a.position - b.position)
          .forEach((tag: any) => {
            newRowsData.push({
              id: tag.position.toString(),
              position: tag.position,
              tagId: tag.tagId,
              gatewayId: tag.gatewayId,
              parentType: tag.parentType,
            });
          });
        setRows({ value: newRowsData, loaded: true });
      })
      .catch((error) => {
        setRows({
          value: [
            {
              id: `1`,
              position: 1,
              tagId: '',
              gatewayId: '',
              parentType: 'gateway',
            },
          ],
          loaded: true,
        });
      });
    setEditSitePreviewDialogOpen(!editSitePreviewDialogOpen);
    setSelectedSite(siteId);
  };

  const isLiveDataMessages = messages?.data;

  useEffect(() => {
    if (isLiveDataMessages) {
      setLiveData((prevState: any) => {
        const filteredItems = prevState?.filter(
          (item: any) => item.data.sourceId !== messages.data.sourceId
        );

        return [...filteredItems, messages];
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages]);

  useEffect(() => {
    if (sortDropdownValue === 'A-Z') {
      setSortByValue('siteName');
      setSortByOrder('asc');
    } else if (sortDropdownValue) {
      setSortByOrder('desc');
      setSortByValue('siteName');
    }
  }, [sortDropdownValue, sortByValue]);

  useEffect(() => {
    if (sortByValue !== 'siteName') {
      setSortDropdownValue('');
    }
  }, [sortByValue]);

  useEffect(() => {
    if (client) {
      client.logEvent('start_page_loaded', undefined, {
        flow: 'main_flow',
        user_id: user.userId,
        customer_id: customerId ?? 'unknown',
        environment: process.env.REACT_APP_ENV || 'development',
        timestamp: Date.now().toString(),
      });
    }
  }, [client, customerId, user.userId]);

  useEffect(() => {
    if (!customerId || !startFlag) return;
    const chosenSkipToId = pages.find((obj) => obj.page === page)?.skipTo ?? '';
    setSkipTo(chosenSkipToId);

    setSiteData((prevData) => ({ ...prevData, loaded: false }));

    const encodedSearchValue = encodeURIComponent(searchValue);

    SitesService.getAll({
      customerId: customerId,
      limit: isMobile ? mobileLimit : rowsPerPage,
      skipTo: chosenSkipToId,
      descending: isMobile
        ? false
        : sortByOrder === 'asc'
        ? false
        : sortByOrder === 'desc'
        ? true
        : false,
      sortBy: isMobile ? DEFAULT_SORT_BY_VALUE : sortByValue,
      search: encodedSearchValue,
    })
      .then((data: NewSite[]) => {
        if (data.length > 0) {
          setSiteData({ value: data, loaded: true });
        } else {
          setSiteData({ value: [], loaded: true });
        }
      })
      .catch((error) => {
        setSiteData({ value: null, loaded: true });
        console.error(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId, rowsPerPage, page, isMobile, sortByValue, sortByOrder, searchValue]);

  const getTags = useCallback(async () => {
    if (!customerId) return;
    try {
      const res = await SitesService.getTagsV2(customerId, []);
      setTags(res.data);
    } catch (error) {
      console.error('Error fetching tags:', error);
    }
  }, [customerId]);

  useEffect(() => {
    getTags();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getTags]);

  useEffect(() => {
    setPages((prevValue) => {
      if (siteData.value?.length && siteData.value.length > 0) {
        const newValue: { page: number; skipTo: string }[] = [];

        prevValue.forEach((value) => {
          if (!newValue.find((newV) => newV.page === value.page)) {
            newValue.push(value);
          }
        });

        if (
          !newValue.find(
            (value: { page: number; skipTo: string }) =>
              siteData.value && value.skipTo === siteData.value[siteData.value.length - 1].id
          )
        ) {
          newValue.push({
            page: page + 1,
            skipTo: (page + 1 + 1).toString(),
          });
        }

        return newValue;
      }
      return prevValue;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteData]);

  useEffect(() => {
    setPages([{ page: 0, skipTo: '1', siteId: '' }]);
    setSiteData({ value: [], loaded: false });
  }, [rowsPerPage, setPages]);

  useEffect(() => {
    if (!customerId) return;

    SitesService.getCount(customerId)
      .then((count) => {
        setSiteDataCount(count);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [customerId]);

  useEffect(() => {
    setPage(0);
    setRowsPerPage(100);
    setStartFlag(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container maxWidth={false} disableGutters data-sm="site-management-content">
      <EditSitePreviewModal
        initialRows={rows}
        dialogOpen={editSitePreviewDialogOpen}
        handleSetDialogOpen={setEditSitePreviewDialogOpen}
        selectedSite={selectedSite}
        handleSetSelectedSite={setSelectedSite}
        getTags={getTags}
      />
      <StyledHeaderSiteContent isMobile={isMobile}>
        {getCurrentRole(customerId, null) === AccessRoles.ACCOUNT_ADMIN ||
        getCurrentRole(customerId, null) === AccessRoles.SUPER_ADMIN ? (
          <>
            <Box sx={addSiteBtnBox} component="div">
              <Button
                to={ADD_SITE_PATH + '?customerId=' + customerId}
                component={Link}
                size={buttonSize}
                variant="contained"
                startIcon={<PlusFillIcon />}
                disabled={!siteData.loaded}
                data-sm="add-site-button"
              >
                {ADD_SITE_BTN}
              </Button>
            </Box>
          </>
        ) : null}
      </StyledHeaderSiteContent>
      <StyledSearchBox>
        <SiteSearchBar searchValue={searchValue} setSearchValue={setSearchValue} />
        {isMobile ? null : (
          <SortListDropdown
            onSortListDropdownChange={setSortDropdownValue}
            sortListDropdownValue={sortDropdownValue}
          />
        )}
      </StyledSearchBox>
      {isMobile ? (
        <MobileSiteList
          data={siteData}
          dataCount={siteDataCount}
          pageNum={page}
          onChangePage={onChangePage}
          rowsPerPage={rowsPerPage}
          onChangeRowsPerPage={onChangeRowsPerPage}
          search={searchValue}
          handleDialogOpen={handleDialogOpen}
          tags={tags}
          skipTo={skipTo}
          liveData={liveData}
          roleAccessInfoBox={roleAccessInfoBox}
        />
      ) : (
        <DesktopSiteTable
          data={siteData}
          dataCount={siteDataCount}
          pageNum={page}
          onChangePage={onChangePage}
          rowsPerPage={rowsPerPage}
          onChangeRowsPerPage={onChangeRowsPerPage}
          onSortByChange={setSortByValue}
          sortBy={sortByValue}
          onSortByOrderChange={setSortByOrder}
          sortByOrder={sortByOrder}
          search={searchValue}
          handleDialogOpen={handleDialogOpen}
          tags={tags}
          liveData={liveData}
          roleAccessInfoBox={roleAccessInfoBox}
        />
      )}
    </Container>
  );
};

export default SiteManagement;
